<template>

    <div class="card bg-light" >
        <div class="card-header">
            <img class="card-img-top m-1"
             :src="( taxonomia.link ? taxonomia.link : 'https://via.placeholder.com/500x300https://via.placeholder.com/500x300')"
             alt="Image Placeholder" 
            >
        </div>
        
        <div class="card-body ">
            <h4 class="card-title"><b>{{ taxonomia.type }}</b></h4>
            <h5> {{ taxonomia.title }}</h5>
            <p class="card-text" v-if="taxonomia.icon">
                Icon:
                <b-icon :icon=taxonomia.icon aria-hidden="true" class="icon-color-1"></b-icon>
            </p>

        </div>
        <div class="card-footer">
            <div class="row">
                <div class="col-auto">
                    <button class="btn btn-warning btn-block mb-2 border-dark" v-b-tooltip.hover :title="taxonomia.description">
                        <b-icon icon="eye"></b-icon>
                    </button>
                </div>
                <div class="col">
                    <button class="btn btn-primary btn-block border-dark" @click="$router.push('/enc-painel/taxonomia/'+taxonomia.id)">Editar</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "CardTaxonomia",
    props: {
        taxonomia: {
            type: Object
        }
    }
}
</script>

<style scoped>
.bg-card{
    background-color: #f9c8276b !important;
}
.btn-block{
    width: 100%;
}
</style>

