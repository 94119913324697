<template>
    <div class="taxonomia-page">
        <div class="row mt-5 mb-5">
            <div class="col-md-12"><h3>Taxonomia</h3></div>
        </div>
        <div class="row">
            <div class="col-md-3 d-flex mb-4" v-for="(taxonomia,index) in taxonomias" :key="index">
                <CardTaxonomia :taxonomia="taxonomia" />
            </div>
        </div>
    </div>
</template>

<script>
import CardTaxonomia from "../../../components/CardTaxonomia";
import TaxonomiaService from "../../../services/TaxonomiaService";

export default {
    name: "Taxonomia",
    components: {CardTaxonomia},
    data() {
        return {
            taxonomias: []
        }
    },
    created() {
        this.taxonomiaService = new TaxonomiaService();
        this.taxonomiaService.getAll().then((response) => {
            this.taxonomias = response.data.data;
        })
    }
}
</script>

<style scoped>

</style>
