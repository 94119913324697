import axios from "axios";

export default class BaseService {
    constructor() {
        this.url = this.getUrl();
        this.requester = axios;
        this.token = localStorage.getItem("site_access_token") ? localStorage.getItem("site_access_token") : null;
        this.config = {
            headers: {Authorization: `Bearer ${this.token}`}
        };
        this.configFormData = {
            headers: {Authorization: `Bearer ${this.token}`,'Content-Type':'multipart/form-data'}
        };
    }

    setConfig(config){
        this.config = {
            headers: {Authorization: `Bearer ${config}`}
        };
    }

    getUrl() {
        // let verifyUrl = document.querySelector('meta[name="endpoint_uri"]').content;
        // let verifyUrl = 'http://localhost/dev/site2022';
        let verifyUrl = 'https://api.encinterativa.com.br';//https://encinterativa.com.br/homolog/site2022
        return verifyUrl;
    }
}
